<template>
    <div class="address-box">
        <nav-index :title="'收货地址'"/>
        <div class="lists">
            <div v-if="showEmyptyImg">
                <div class="none-list">
                    <img src="../../assets/images/none.svg" alt="">
                    <div class="none">这里什么都没有</div>
                </div>
                <div class="add-address">
                    <div class="button" @click="goCreateAddress">添加地址</div>
                </div>
            </div>
            <div v-else style="margin-bottom:100px;">
                <!-- <div class="tips" v-show="lists.length > 0">* 未设置默认地址</div> -->
                <div class="card" v-for="list in lists" :key="list.id">
                    <div class="carrd">
                        <div class="lg-10" @click="selectAddress(list)">
                            <div class="row">
                                <img src="../../assets/images/selected.png" alt="" width="22" height="22" v-show="list.selectedIcon">
                                <div class="row" >
                                    <div class="name">{{ list.name }}</div>
                                    <div class="phone">{{ list.phone }}</div>
                                    <div class="defalut" v-show="list.defaultAddress">
                                        <div class="label">默认</div>
                                    </div>
                                </div>
                            </div>
                            <div class="address"> {{ list.address }} </div>
                        </div>
                        <div class="img">
                            <img src="../../assets/images/write.png" alt="" width="18" height="16" @click="editAddress(list)">
                        </div>
                    </div>
                </div>
                <div class="add-address-footer" v-show="lists.length > 0">
                    <div class="button" @click="goCreateAddress">添加地址</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavIndex from '../../components/nav/index.vue'
import { queryAllAddressByUid } from '@/api/api'

export default {
    name: 'Address',
    components: {
      NavIndex
    },
    created(){

    },
    mounted(){
        this.getData()
    },
    data:()=>{
      return {
        lists:[],
        showEmyptyImg: false,
        userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0] : {}
      }
    },
    methods:{
        getData(){
            queryAllAddressByUid({uid: this.userInfo.uid}).then((res)=>{
                this.lists.length = 0
                if(res.data.data.length > 0){
                    res.data.data.forEach((item)=>{
                        this.showEmyptyImg = false
                        let temp = {}
                        temp.id = item.aid
                        temp.name = item.nickname
                        temp.phone = item.phone
                        temp.area = item.area
                        temp.desc = item.desc
                        temp.address = item.area + ' ' + item.desc
                        temp.defaultAddress = Number(item.default)
                        temp.selectedIcon = Number(this.$route.query.aid) === item.aid ? true : false
                        temp.type = item.type
                        this.lists.push(temp)
                    })
                } else {
                    this.showEmyptyImg = true
                }
            })
        },
        goCreateAddress(){
            if(this.$route.query.from){
                this.$router.push({ path:'/create-address', query:{ from: this.$route.query.from } })
            } else {
                if(this.$route.query.oid){
                    this.$router.push({ path:'/create-address', query:{ oid: this.$route.query.oid } })
                } else {
                    this.$router.push('/create-address')
                }
            }
        },
        editAddress(list){
            sessionStorage.setItem('editAddress', JSON.stringify(list))
            if(this.$route.query.from){
                this.$router.push({ name:'CreateAddress', query:{ text: '修改地址', aid: list.id, from: this.$route.query.from} })
            } else {
                if(this.$route.query.oid){
                    this.$router.push({ name:'CreateAddress', query:{ text: '修改地址', aid: list.id, oid: this.$route.query.oid } })
                } else {
                    this.$router.push({ name:'CreateAddress', query:{ text: '修改地址', aid: list.id} })
                }
            }
        },
        selectAddress(list){
            if(!this.$route.query.from){
                this.$router.push({path:'/pay', query:{ selectedAddr: list, oid: this.$route.query.oid, gid: this.$route.query.gid }})
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.address-box {
    min-height: calc(100vh - 50px);
    padding-top: 50px;
    .lists {
        padding: 5px 15px 15px;
        .add-address {
            display: flex;
            justify-content: center;
            .button {
                height: 38px;
                width: 40%;
                display: flex;
                line-height: 30px;
                border-radius: 30px;
                align-items: center;
                font-size: 14px;
                color: #fff;
                background: #ec745b;
                justify-content: center;
                border: 1px solid #ec745b;
                cursor: pointer;
            }
        }
        .none-list {
            margin: 60px auto 20px;
            width: 100%;
            max-width: 625px;
            .none {
                margin: 10px 0 0;
                color: #969699;
            }
            img {
                min-height: 142px;
            }
        }
        .tips {
            text-align: left;
            font-size: 13px;
            color: #ec745b;
            display: flex;
            align-items: center;
            margin: 5px 0;
        }
        .card {
            text-align: left;
            background: #fff;
            margin: 10px 0;
            padding: 15px 40px 15px 40px;
            border-radius: 5px;
            position: relative;
            .row {
                display: flex;
                height: 25px;
                line-height: 25px;
                padding-bottom: 3px;
                .name {
                    padding-right: 10px;
                    font-size: 18px;
                    font-weight: 600;
                }
                .phone {
                    color:#969699;
                    font-size: 17px;
                }
                .defalut {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                    .label {
                        background: rgba(0,0,0,0.8);
                        min-height: 15px;
                        line-height: 15px;
                        padding: 1px 4px;
                        color: #fff;
                        font-size: 12px;
                    }
                }
                img {
                    padding: 0 5px 0 0;
                    position: absolute;
                    left: 10px;
                    top: 26px;
                }
            }
            .address {
                font-size: 16px;
                word-break: break-all;
            }
            .carrd {
                display:flex;
                align-items: center;
                .img {
                    position: absolute;
                    right: 15px;
                }
            }
        }
        .add-address-footer {
            display: flex;
            justify-content: center;
            position: fixed;
            left: 0;
            right: 0;
            bottom: constant(safe-area-inset-bottom);
            bottom: env(safe-area-inset-bottom);
            max-width: 625px;
            margin: 0 auto;
            margin-bottom: 10px;
            .button {
                height: 38px;
                width: 80%;
                display: flex;
                line-height: 30px;
                border-radius: 30px;
                align-items: center;
                font-size: 14px;
                color: #fff;
                background: #ec745b;
                justify-content: center;
                border: 1px solid #ec745b;
                cursor: pointer;
            }
        }
    }
}
</style>